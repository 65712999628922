.new-post-container .x {
    position: absolute;
    left: 4px;
    top: 4px;
}

.new-post-container .x {
    background-color: black;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    height: 24px;
    width: 24px;
}

.new-post-container .x svg {
    font-size: 24px;
    color: rgb(255, 255, 255);
    z-index: 15;
}
