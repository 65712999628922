.resume-perfil-container {
    padding-top: 66px;

    display: flex;

    flex-direction: column;

    align-items: center;
}

.resume-perfil-container .photo {
    width: 126px;

    height: 126px;

    overflow: hidden;

    border-radius: 50%;

    -webkit-border-radius: 50%;

    -moz-border-radius: 50%;

    -ms-border-radius: 50%;

    -o-border-radius: 50%;

    border: #e82f3e 2px solid;
}

.resume-perfil-container .photo img {
    width: 126px;

    height: 126px;

    object-fit: cover;
}

.resume-perfil-container .basic {
    margin-top: 4px;

    width: 100%;
}

.resume-perfil-container .basic h1 {
    font-weight: normal;

    font-size: 18px;

    line-height: 25px;

    text-align: center;
}

.resume-perfil-container .basic h2 {
    font-weight: normal;

    font-size: 15px;

    line-height: 20px;

    text-align: center;
}

.resume-perfil-container .numeros {
    margin-top: 24px;

    display: flex;

    justify-content: space-between;

    align-items: center;

    width: 70%;
}

.resume-perfil-container .numeros .numero {
    display: flex;

    flex-direction: column;

    align-items: center;

    justify-content: center;
}

.resume-perfil-container .numeros .numero h3 {
    font-weight: bold;

    font-size: 18px;

    line-height: 21px;

    text-align: center;
}

.resume-perfil-container .numeros .numero p {
    font-weight: normal;

    font-size: 15px;

    line-height: 18px;

    text-align: center;
}

.resume-perfil-container .btn {
    margin-top: 16px;
}

.resume-perfil-container .btn.seg button {
    background: #f3bcc0;

    color: #141414;
}

.resume-perfil-container .btn button {
    background: #e82f3e;

    border-radius: 3px;

    border: 0;

    width: 157px;

    height: 43px;

    font-weight: 600;

    font-size: 17px;

    line-height: 20px;

    text-transform: uppercase;

    color: white;

    transition: all 0.5s;
}

.resume-perfil-container .btn-meu button {
    background: #e82f3e;

    border-radius: 3px;

    border: 0;

    width: 113px;

    height: 43px;

    font-weight: 600;

    font-size: 17px;

    line-height: 20px;

    text-transform: uppercase;

    color: white;

    transition: all 0.5s;
}

.resume-perfil-container .btn-meu {
    margin-top: 16px;
}

.resume-perfil-container .btn-meu button + button {
    background: #ea662d;

    width: 45px;

    margin-left: 16px;
}

.resume-perfil-container .resumo {
    margin-top: 16px;

    width: 70%;
}

.resume-perfil-container .resumo p {
    font-weight: normal;

    font-size: 15px;

    line-height: 20px;

    text-align: center;

    color: #000000;
}

.social-icons-share {
    margin: 16px;
    display: flex;
    gap: 8px;
}
