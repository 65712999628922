.desktop-hero-container {
    width: 100vw;
    height: 350px;
    padding-left: 10vw;

    background-image: url("../../../assets/bg-hero.jpg");
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.desktop-hero-container p {
    font-size: 36px;
    font-weight: 600;
}

.desktop-hero-container p b {
    font-weight: 900;
    font-size: 40px;
}

.desktop-hero-container a {
    margin-top: 16px;
    background: #e82f3e;
    padding: 7px 50px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-decoration: none;
}
