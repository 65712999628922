.desktop-with-photo-container {
    height: 370px;

    width: 100%;

    position: relative;

    overflow: hidden;
}

.desktop-with-photo-container .image {
    height: 370px;

    width: 100%;

    object-fit: contain;

    position: absolute;
}

.desktop-with-photo-container .delete {
    position: absolute;
    bottom: 6px;
    right: 6px;
    cursor: pointer;

    color: white;
    background-color: black;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    z-index: 999;
}
