.no-photo-container {
    height: 100vw;
    width: 100vw;
    background: url("../../../assets/bg-field.jpg");
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
}

.no-photo-container button {
    background-color: #e82f3f93;
    padding: 12px;
    border: 0;
    outline: 0;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
