.desktop-listaposts-container {
    padding: 20px 60px;
}

.desktop-postonlist-container {
    border-radius: 8px;
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.25);
    width: 21vw;
    max-width: 370px;
    height: auto !important;
    background-color: #dedede;
    padding: 24px !important;

    margin-bottom: 32px;
}

@media (max-width: 1300px) {
    .desktop-postonlist-container {
        width: 50vw;
    }
}

.desktop-postonlist-container .slick-next {
    right: 4px;
    z-index: 500;
}

.desktop-postonlist-container .slick-prev {
    left: 4px;
    z-index: 500;
}

.desktop-postonlist-container .slick-dots {
    bottom: 4px;
    z-index: 500;
}

.desktop-postonlist-container .photos {
    position: relative;
}

.desktop-postonlist-container .photo-tog {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.desktop-postonlist-container .photo-tog .top {
    object-fit: contain;
    z-index: 999;
}

.desktop-postonlist-container .photo-tog .bottom {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 998;
    object-fit: cover;
    filter: blur(3px) brightness(50%) contrast(110%);
    -webkit-filter: blur(3px) brightness(50%) contrast(110%);
    transform: scale(1.1, 1.1);
}

.desktop-postonlist-container .photos {
    height: fit-content;
}

.desktop-postonlist-container .photos .classif {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 5px 8px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
    z-index: 500;
}

.desktop-postonlist-container .photos .classif img {
    height: 27px;
    width: auto;
    margin-right: 5px;
}

.desktop-postonlist-container .photo-tog img {
    width: 100%;
    max-height: 350px;
}

.desktop-postonlist-container .autor {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.desktop-postonlist-container .autor .left {
    display: flex;
    align-items: center;
}

.desktop-postonlist-container .autor .left img {
    width: 34px;
    height: 34px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
}

.desktop-postonlist-container .autor .left .name {
    font-size: 18px;
}

.desktop-postonlist-container .autor .left .username {
    font-size: 14px;
}

.desktop-postonlist-container .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.desktop-postonlist-container .right svg {
    height: 25px;
    width: 25px;
}

.desktop-postonlist-container .right p {
    font-size: 20px;
    margin-left: 4px;
}

.desktop-postonlist-container .ver-mais {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 8px 0;
}

.desktop-postonlist-container .ver-mais button {
    outline: 0;
    border: 0;
    padding: 7px 15px;
    background: #e82f3e;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    text-decoration: none;
}

.desktop-postonlist-container .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto !important;
    overflow: hidden;
}

.desktop-postonlist-container .desc .place {
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}

.desktop-postonlist-container .desc .description {
    text-align: center;
    font-size: 12px;
}

.post-list-grid {
    grid-gap: 25px !important;
    gap: 25px !important;
}
