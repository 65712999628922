.mobile-landingPage-container {
    width: 100vw;
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url("../../../assets/lp-desktop.jpg");
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile-landingPage-container .texts {
    min-width: 400px;
    width: 30vw;
    text-align: center;
}

.mobile-landingPage-container .texts img {
    width: 30%;
}

.mobile-landingPage-container .texts h1 {
    color: white;
    text-shadow: 0px 3px 3px #000;
    text-align: center;

    margin-top: 8px;
}

.mobile-landingPage-container .texts h2 {
    color: white;
    text-shadow: 0px 3px 3px #000;
    text-align: center;
}

.mobile-landingPage-container form {
    min-width: 300px;
    width: 23vw;
    margin-top: 48px;
    background-color: white;
    border-radius: 10px;

    padding: 15px 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile-landingPage-container form p {
    width: 90%;
    color: #575757;
    font-size: 10px;
    text-align: left;
    margin-bottom: 4px;
    font-weight: 300;
}

.mobile-landingPage-container form p.error {
    font-size: 12px;
    text-align: center;
    color: #e82f3e;
}

.mobile-landingPage-container form textarea {
    padding-left: 16px;
    padding: 8px;
    width: 90%;
    height: 40px;
    margin-bottom: 8px;
    border: 0;
    font-size: 14px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.mobile-landingPage-container form input {
    padding-left: 16px;
    width: 90%;
    height: 40px;
    margin-bottom: 8px;
    border: 0;
    font-size: 14px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.mobile-landingPage-container form input.error,
.mobile-landingPage-container form textarea.error {
    border: 1px solid #e82f3e;
}

.mobile-landingPage-container form button {
    margin-top: 16px;
    outline: 0;
    border: 0;
    width: 90%;
    height: 40px;
    background: #e82f3e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-decoration: none;
}

.mobile-landingPage-container form a {
    font-weight: 300;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    margin-top: 16px;
    margin-right: 0;
    color: #575757;
}

.mobile-landingPage-container form a + a {
    margin-top: 4px;
}

.mobile-landingPage-container form h5 {
    text-align: center;
    width: 80%;
    margin-top: 16px;
    margin-bottom: 8px;
}

.mobile-landingPage-container .eye {
    position: absolute;
    right: 24px;
    top: 47%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.desktop-landingPage-container .eye {
    position: absolute;
    right: 36px;
    top: 47%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
