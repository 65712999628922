.comentarios-container {
    width: 100vw;
    height: 85vh;
    position: fixed;
    bottom: 0;
    z-index: 999;
    background-color: white;
    border-radius: 15px 15px 0px 0px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.4);
}

.comentarios-container .head {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 8px;
    justify-content: space-between;
}

.comentarios-container .head p {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
}

.comentarios-container .head .none {
    margin-left: 16px;
    opacity: 0;
}

.comentarios-container .head .x {
    margin-right: 16px;
    font-size: 18px;
}

.comentarios-container .lista {
    padding-top: 8px;
    padding-bottom: 16px;
    overflow-y: auto;
    height: 72vh;
}

.comentarios-container .inputs {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 100%;
    bottom: 16px;
    position: absolute;
}

.comentarios-container .inputs .right {
    margin: 16px;
    width: 100%;
}

.comentarios-container .inputs .right form {
    display: flex;
}

.comentarios-container .inputs .right input {
    flex: 1;
    background: #ebebeb;
    border: 1px solid #ababab;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    padding-left: 8px;
}

.comentarios-container .inputs .right button {
    background: #e82f3e;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50%;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comentarios-container .inputs .right button svg {
    font-size: 32px;
}

.opacidade {
    top: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 600;
    background-color: rgba(0, 0, 0, 0.7);
}

.comentarios-container .deslogado {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comentarios-container .deslogado p {
    margin-left: 16px;
    width: 60%;
    font-size: 18px;
}

.comentarios-container .deslogado a {
    margin-right: 16px;
    background-color: #e82f3e;
    color: white;
    text-decoration: none;
    font-size: 20px;
    padding: 8px 12px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
