.mobile-landingPage-container form img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;

    margin-bottom: 16px;
}

.mobile-landingPage-container form img.big {
    width: 300px;
    height: 300px;
}

.mobile-landingPage-container.cad {
    height: fit-content;
    background-size: cover;
    padding: 48px 0;
    min-height: 100vh;
}

.mobile-landingPage-container form .checkbox {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.mobile-landingPage-container form .checkbox input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-bottom: 0;
}

.label-error {
    color: brown;
}
