.menu-inferior-container {
    position: fixed;
    bottom: 0;
    z-index: 90;

    width: 100vw;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    background: #ebebeb;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
}

.menu-inferior-container.no {
    position: fixed;
    bottom: 0;
    z-index: 90;

    width: 100vw;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    background: transparent;
    box-shadow: none;
}

.menu-inferior-container a .ico-pqn {
    color: black;
    font-size: 23px;
    transition: all 0.5s;
}

.menu-inferior-container a .ico-pqn.active {
    padding-bottom: 4px;
    border-bottom: 2px solid;
    font-size: 29px;
}

.menu-inferior-container a .ico-grn {
    color: black;
    font-size: 35px;
}

.menu-inferior-container.no a .ico-pqn,
.menu-inferior-container.no a .ico-grn {
    color: white;
}
