.desktop-error-ovl {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(
        to right bottom,
        rgba(1, 1, 1, 0.8),
        rgba(1, 1, 1, 0.6)
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.desktop-error-ovl p {
    padding-top: 15px;
    color: white;
    font-size: 20px;
    text-align: center;
}
.desktop-error-ovl h1 {
    font-size: 50px !important;
    color: white;
    font-size: 20px;
}

.desktop-error-ovl > div {
    margin-top: 48px;
    display: flex;
}

.desktop-error-ovl .btn {
    background: #e82f3e;
    padding: 10px 25px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-decoration: none;
}

.desktop-error-ovl .btn:not(:last-child) {
    margin-right: 15px;
}

@media only screen and (max-width: 950px) {
    .desktop-error-ovl h1 {
        font-size: 30px !important;
    }

    .desktop-error-ovl > div {
        flex-direction: column;
        align-items: center;
    }

    .desktop-error-ovl .btn {
        text-align: center;
    }

    .desktop-error-ovl .btn:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
