.desktop-modal-termos {
    max-width: 750px;
    width: 70%;
    max-height: 80vh;
    height: fit-content;
    overflow-y: scroll;
    padding: 16px;
    background-color: white;
    border-radius: 5px;
    z-index: 999;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.desktop-modal-termos .close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}
