.desktop-pub-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.desktop-pub-container > div {
    padding: 16px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop-pub-container > div .close {
    position: absolute;
    top: 6px;
    right: 18px;
    z-index: 999;
    background-color: transparent;
    border: 0;
    outline: 0;
    font-size: 24px;
}

.desktop-pub-container > div .left {
    min-width: 35vw;
    width: 500px;
    max-width: 90vw;
    height: 70vh;
}

.desktop-pub-container > div .left .photos-tog {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.desktop-pub-container > div .left .photos-tog .top {
    width: 100%;
    height: auto;
    position: absolute;
}

.desktop-pub-container > div .left .photos-tog .top {
    width: 100%;
    height: 70vh;
    position: relative;
    object-fit: contain;
    z-index: 999;
}

.desktop-pub-container > div .left .photos-tog .bottom {
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 0;
    object-fit: cover;
    filter: blur(3px) brightness(50%) contrast(110%);
    -webkit-filter: blur(3px) brightness(50%) contrast(110%);
    transform: scale(1.1, 1.1);
}

.desktop-pub-container > div .right {
    min-width: 35vw;
    width: 400px;
    max-width: 45vw;
    height: 70vh;
    margin-left: 16px;
    padding-top: 8px;
}

.desktop-pub-container > div .right .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.desktop-pub-container > div .right .row .delete {
    font-size: 18px;
    color: #141414;
    cursor: pointer;
}

.desktop-pub-container > div .right .author {
    display: flex;
    align-items: center;
}

.desktop-pub-container > div .right .author img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #fff;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.4));
}

.desktop-pub-container > div .right .author > div {
    margin-left: 8px;
}

.desktop-pub-container > div .right .author > div a {
    text-decoration: none;
    color: black;
}

.desktop-pub-container > div .right .author .nome {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
}

.desktop-pub-container > div .right .author .username {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    margin-top: 3px;
}

.desktop-pub-container > div .right .post {
    margin-top: 8px;
}

.desktop-pub-container > div .right .post h1 {
    font-size: 20px;
    line-height: 17px;
    color: #141414;
    font-weight: 300;
}

.desktop-pub-container > div .right .post p {
    margin-top: 8px;
    font-size: 15px;
    line-height: 18px;
    color: #141414;
}

.desktop-pub-container > div .right .pontos {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 24px;
    padding: 2px 5px;

    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.desktop-pub-container > div .right .pontos .esq {
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop-pub-container > div .right .pontos .esq p {
    font-size: 20px;
    margin-left: 4px;
}

.desktop-pub-container > div .right .pontos .esq svg {
    height: 25px;
    width: 25px;
}

.desktop-pub-container > div .right .pontos .dir {
    display: flex;
    align-items: center;
}

.desktop-pub-container > div .right .pontos .dir svg {
    padding-top: 2px;
    height: 27px;
    width: 25px;
    margin-left: 3px;
}

.desktop-pub-container > div .right .pontos .dir p {
    font-size: 12px;
}

.desktop-pub-container > div .right .pontos .dir .deletar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-right: 16px;
    cursor: pointer;
}

.desktop-pub-container > div .right .comments {
    margin-top: 16px;
    padding: 0px 32px;
    overflow-y: auto;
}

.desktop-pub-container > div .right .comments form {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.desktop-pub-container > div .right .comments form input {
    flex: 1;
    margin-right: 4px;
    background-color: #ebebeb;
    border: 0;
    outline: 0;
    height: 40px;
    border-radius: 6px;
    padding-left: 16px;
    border: 1px solid #ababab;
}

.desktop-pub-container > div .right .comments form button {
    height: 40px;
    width: 40px;
    background-color: #e82f3e;
    border: 0;
    outline: 0;
    border-radius: 50%;
    font-size: 20px;
}

.desktop-pub-container > div .right .comments form button svg {
    width: 30px;
    height: 30px;
    margin-top: 4px;
}

.desktop-pub-container > div .right .comments .comment {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;
}

.desktop-pub-container > div .right .comments .comment > div {
    display: flex;
    align-items: center;
}

.desktop-pub-container > div .right .comments .comment img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
}

.desktop-pub-container > div .right .comments .comment .name {
    font-size: 14px;
    font-weight: 700;
}

.desktop-pub-container > div .right .comments .comment .comm {
    font-size: 12px;
}

.black-ovl {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.6);
    z-index: 900;
}
