.desktop-landingPage-container {
    width: 100vw;
    min-height: 100vh;
    background-image: url("../../../assets/lp-desktop.jpg");
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop-landingPage-container .texts {
    min-width: 400px;
    width: 30vw;
    margin-right: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.desktop-landingPage-container .texts img {
    width: 30%;
}

.desktop-landingPage-container .texts h1 {
    color: white;
    text-shadow: 0px 3px 3px #000;
    text-align: center;

    margin-top: 16px;
}

.desktop-landingPage-container .texts h2 {
    color: white;
    text-shadow: 0px 3px 3px #000;
    text-align: center;
}

.desktop-landingPage-container form {
    min-width: 300px;
    width: 23vw;
    margin-left: 40px;
    background-color: white;
    border-radius: 10px;

    padding: 15px 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.desktop-landingPage-container form p {
    width: 90%;
    color: #575757;
    font-size: 10px;
    text-align: left;
    margin-bottom: 4px;
    font-weight: 300;
}

.desktop-landingPage-container form p.error {
    font-size: 12px;
    text-align: center;
    color: #e82f3e;
}

.desktop-landingPage-container form textarea {
    padding-left: 16px;
    padding: 8px;
    width: 90%;
    height: 40px;
    margin-bottom: 8px;
    border: 0;
    font-size: 14px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.desktop-landingPage-container form input {
    padding-left: 16px;
    width: 90%;
    height: 40px;
    margin-bottom: 8px;
    border: 0;
    font-size: 14px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.desktop-landingPage-container form input.error,
.desktop-landingPage-container form textarea.error {
    border: 1px solid #e82f3e;
}

.desktop-landingPage-container form button {
    margin-top: 16px;
    outline: 0;
    border: 0;
    width: 90%;
    height: 40px;
    background: #e82f3e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-decoration: none;
}

.desktop-landingPage-container form a {
    font-weight: 300;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    margin-top: 16px;
    margin-right: 0;
    color: #575757;
}

.desktop-landingPage-container form a + a {
    margin-top: 4px;
}
