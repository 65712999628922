.desktop-profile-container {
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 80px;
}

.desktop-profile-container .row {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.desktop-profile-container .row:first-child {
    margin-bottom: 30px;
}

.desktop-profile-container .row .left {
    display: flex;
    align-items: center;
}

.desktop-profile-container .row .left img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.desktop-profile-container .row .left .name {
    margin-left: 32px;
}

.desktop-profile-container .row .left .name h1 {
    font-size: 32px;
    font-weight: 400;
}

.desktop-profile-container .row .left .name h2 {
    font-size: 20px;
    font-weight: 400;
}

.desktop-profile-container .row .right {
    display: flex;
    align-items: center;
}

.desktop-profile-container .row .right .contador {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.desktop-profile-container .row .right .contador:not(:last-child) {
    margin-right: 30px;
}

.desktop-profile-container .row .right .contador h3 {
    font-size: 26px;
    font-weight: 700;
}

.desktop-profile-container .row .right .contador p {
    font-size: 16px;
    font-weight: 400;
}

.desktop-profile-container .row .left2 p {
    font-size: 20px;
    max-width: 350px;
}

.desktop-profile-container .row .right2 button {
    outline: 0;
    border: 0;
    padding: 10px 70px;
    background: #e82f3e;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    text-decoration: none;
}

.desktop-profile-container .row .right2 .btn-meu {
    margin-top: 16px;
}

.desktop-profile-container .row .right2 .btn-meu button + button {
    background: #ea662d;

    margin-left: 16px;
    text-align: center;
    padding: 10px 20px;
}

.desktop-profile-container .row .right2 .seguindo {
    background: #f3bcc0;
    color: #141414;
}
