.menu-desktop-sup-container {
    width: 100vw;
    height: 50px;

    background: rgba(20, 20, 20, 0.8);
    position: fixed;

    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 100;
}

.menu-desktop-sup-container .left {
    margin-left: 40px;
}

.menu-desktop-sup-container .left img {
    height: 30px;
}

.menu-desktop-sup-container .right {
    margin-right: 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-desktop-sup-container .right .pesquisar {
    margin-right: 5vw;
}

.menu-desktop-sup-container .right .pesquisar form {
    display: flex;
    align-items: center;
}

.menu-desktop-sup-container .right .pesquisar form input {
    font-weight: 300;
    font-size: 11px;
    padding-left: 8px;
    width: 23vw;
    height: 25px;
    outline: 0;
    border: 0;
    background: #ffffff;
    border-radius: 5px 0px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-desktop-sup-container .right .pesquisar form input.error {
    border: 2px solid #e82f3e;
}

.menu-desktop-sup-container .right .pesquisar form button {
    background: #e82f3e;
    border-radius: 0px 5px 5px 0px;
    height: 25px;
    width: 30px;
    outline: 0;
    border: 0;
    color: white;
    font-size: 11px;
}

.menu-desktop-sup-container .right .menu {
    display: flex;
    align-items: center;
}

.menu-desktop-sup-container .right .menu svg {
    cursor: pointer;
    font-size: 23px;
    color: white;
    margin-right: 8px;
}

.menu-desktop-sup-container .right .menu .plus {
    margin-right: 16px;
}

.menu-desktop-sup-container .right .menu .perfil {
    cursor: pointer;
    margin-left: 8px;

    border: 1px solid #ffffff;
    width: 25px;
    height: 25px;

    overflow: hidden;
    border-radius: 50%;
}

.menu-desktop-sup-container .right .menu .perfil img {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.menu-desktop-sup-container .right .menu .naologado {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 16px;
}

.menu-desktop-sup-container .right .menu .naologado .inv {
    margin-right: 12px;
    background: none;
    outline: 0;
    border: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: white;
}

.menu-desktop-sup-container .right .menu .naologado .sol {
    background: #e82f3e;
    border-radius: 5px;
    padding: 6px 8px;
    outline: 0;
    border: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: white;
}

.rc-dropdown-menu button {
    border: 0;
    outline: 0;
    text-align: right;
    font-size: 14px;
    background-color: transparent;
    padding: 5px 20px;
}
