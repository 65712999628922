.popup-pre-accept {
    width: 100%;
    height: 150px;

    position: fixed;
    bottom: 0;

    background-color: white;
    padding: 0 54px;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 999;
}

.popup-pre-accept .content {
    width: 150%;
    margin-bottom: 16px;
}

.popup-pre-accept .content p {
    text-align: center;
    font-size: 24px;
}

.popup-pre-accept .close {
    position: absolute;
    top: 8px;
    right: 8px;

    width: 20px;
    height: 20px;

    border: 1px solid #d4d4d4;
    border-radius: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-pre-accept .close svg {
    color: #818181;

    cursor: pointer;
}

.popup-pre-accept .buttons {
    display: flex;
    gap: 8px;
    width: 100%;
}

.popup-pre-accept .buttons button{
    width: 100%;
    height: 40px;
    border-radius: 3px;
    outline: 0;
    border: 0;

    text-align: center;
    text-transform: uppercase;
    font-weight: 700;

    color: white;
    cursor: pointer;

}

.popup-pre-accept .buttons .grey-button {
    background: #818181;
}

.popup-pre-accept .buttons .red-button {
    background: #E82F3E;
}

.popup {
    position: fixed;

    max-width: 50%;
    width: 500px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: white;
    padding: 32px 16px 16px 16px;
    border-radius: 4px;

    z-index: 999;
}

.popup .slick-arrow::before {
    color: #E82F3E;
}

.popup .close {
    position: absolute;
    top: 8px;
    right: 8px;

    width: 20px;
    height: 20px;

    border: 1px solid #d4d4d4;
    border-radius: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup .close svg {
    color: #818181;

    cursor: pointer;
}