.mobile-popup-pre-accept {

    width: 100%;

    height: 150px;



    position: fixed;

    bottom: 0;



    background-color: white;



    display: flex;

    flex-direction: column;

    justify-content: center;

    align-items: center;



    z-index: 999;

}



.mobile-popup-pre-accept .content {

    width: 70%;

    margin-bottom: 16px;

}



.mobile-popup-pre-accept .content p {

    text-align: center;

}



.mobile-popup-pre-accept .close {

    position: absolute;

    top: 8px;

    right: 8px;



    width: 20px;

    height: 20px;



    border: 1px solid #d4d4d4;

    border-radius: 150px;



    display: flex;

    justify-content: center;

    align-items: center;

}



.mobile-popup-pre-accept .close svg {

    color: #818181;



    cursor: pointer;

}



.mobile-popup-pre-accept .buttons {

    display: flex;

    gap: 8px;

}



.mobile-popup-pre-accept .buttons button{

    width: 40vw;

    height: 40px;

    border-radius: 3px;

    outline: 0;

    border: 0;



    text-align: center;

    text-transform: uppercase;

    font-weight: 700;



    color: white;



}



.mobile-popup-pre-accept .buttons .grey-button {

    background: #818181;

}



.mobile-popup-pre-accept .buttons .red-button {

    background: #E82F3E;

}



.mobile-popup {

    position: fixed;



    width: 90%;

    left: 50%;

    top: 50%;

    transform: translate(-50%, -50%);



    background-color: white;

    padding: 16px 16px 16px 16px;

    border-radius: 4px;



    z-index: 999;

}



.mobile-popup .slick-arrow::before {

    color: #E82F3E;

}



.mobile-popup .close {

    margin-bottom: 8px;

    width: 100%;

    display: flex;

    justify-content: space-between;

    align-items: center;

}


.mobile-popup .close .buttons {
    display: flex;
    gap: 8px;

}

.mobile-popup .close .buttons button {
    background-color: #E82F3E;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    outline: 0;
    border: 0;
}


.mobile-popup .close svg {

    color: #818181;

    border: 1px solid #d4d4d4;

    border-radius: 150px;

    cursor: pointer;

}