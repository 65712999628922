.icon-close-profile {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 20px;
    height: 20px;
    background: white;
    color: black;
    border-radius: 50%;
    cursor: pointer;
}

.desktop-landingPage-container form h5 {
    text-align: center;
    width: 80%;
    margin-top: 16px;
    margin-bottom: 8px;
}
