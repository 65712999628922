.with-photo-container {
    height: 100vw;
    width: 100vw;
    position: relative;
    overflow: hidden;
}

.with-photo-container .image {
    height: 100vw;
    width: 100vw;
    object-fit: contain;
    position: absolute;
}

.with-photo-container .blur {
    height: 100vw;
    width: 100vw;
    object-fit: cover;
    position: absolute;
    filter: blur(2px);
    -webkit-filter: blur(2px);
    transform: scale(1.03);
    filter: brightness(50%) contrast(110%);
    -webkit-filter: brightness(50%) contrast(110%);
}
