@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body,
html {
    /* overflow-x: hidden; */
}

body {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    width: 100vw;
    height: 100vh;
    /* overflow: hidden; */
}

input,
button,
textarea {
    font-family: "Open Sans", sans-serif;
}

button {
    cursor: pointer;
}

.App {
    min-height: calc(var(--vh, 1vh) * 100);
}

@media only screen and (min-width: 950px) {
    body {
        overflow-x: hidden;
    }
}
