@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body,
html {
    /* overflow-x: hidden; */
}

body {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    width: 100vw;
    height: 100vh;
    /* overflow: hidden; */
}

input,
button,
textarea {
    font-family: "Open Sans", sans-serif;
}

button {
    cursor: pointer;
}

.App {
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
}

@media only screen and (min-width: 950px) {
    body {
        overflow-x: hidden;
    }
}

.desktop-landingPage-container {
    width: 100vw;
    min-height: 100vh;
    background-image: url(/static/media/lp-desktop.b48bb11a.jpg);
    background-size: cover;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-landingPage-container .texts {
    min-width: 400px;
    width: 30vw;
    margin-right: 40px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-landingPage-container .texts img {
    width: 30%;
}

.desktop-landingPage-container .texts h1 {
    color: white;
    text-shadow: 0px 3px 3px #000;
    text-align: center;

    margin-top: 16px;
}

.desktop-landingPage-container .texts h2 {
    color: white;
    text-shadow: 0px 3px 3px #000;
    text-align: center;
}

.desktop-landingPage-container form {
    min-width: 300px;
    width: 23vw;
    margin-left: 40px;
    background-color: white;
    border-radius: 10px;

    padding: 15px 5px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.desktop-landingPage-container form p {
    width: 90%;
    color: #575757;
    font-size: 10px;
    text-align: left;
    margin-bottom: 4px;
    font-weight: 300;
}

.desktop-landingPage-container form p.error {
    font-size: 12px;
    text-align: center;
    color: #e82f3e;
}

.desktop-landingPage-container form textarea {
    padding-left: 16px;
    padding: 8px;
    width: 90%;
    height: 40px;
    margin-bottom: 8px;
    border: 0;
    font-size: 14px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.desktop-landingPage-container form input {
    padding-left: 16px;
    width: 90%;
    height: 40px;
    margin-bottom: 8px;
    border: 0;
    font-size: 14px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.desktop-landingPage-container form input.error,
.desktop-landingPage-container form textarea.error {
    border: 1px solid #e82f3e;
}

.desktop-landingPage-container form button {
    margin-top: 16px;
    outline: 0;
    border: 0;
    width: 90%;
    height: 40px;
    background: #e82f3e;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-decoration: none;
}

.desktop-landingPage-container form a {
    font-weight: 300;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    margin-top: 16px;
    margin-right: 0;
    color: #575757;
}

.desktop-landingPage-container form a + a {
    margin-top: 4px;
}

.desktop-loading-ovl {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(
        to right bottom,
        rgba(1, 1, 1, 0.8),
        rgba(1, 1, 1, 0.6)
    );
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 999;
}
.desktop-loading-ovl p {
    padding-top: 50px;
    color: white;
    font-size: 20px;
}

.bg_full {
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/lp-desktop.b48bb11a.jpg);
    background-size: cover;
}

.desktop-error-ovl {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(
        to right bottom,
        rgba(1, 1, 1, 0.8),
        rgba(1, 1, 1, 0.6)
    );
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.desktop-error-ovl p {
    padding-top: 15px;
    color: white;
    font-size: 20px;
    text-align: center;
}
.desktop-error-ovl h1 {
    font-size: 50px !important;
    color: white;
    font-size: 20px;
}

.desktop-error-ovl > div {
    margin-top: 48px;
    display: -webkit-flex;
    display: flex;
}

.desktop-error-ovl .btn {
    background: #e82f3e;
    padding: 10px 25px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-decoration: none;
}

.desktop-error-ovl .btn:not(:last-child) {
    margin-right: 15px;
}

@media only screen and (max-width: 950px) {
    .desktop-error-ovl h1 {
        font-size: 30px !important;
    }

    .desktop-error-ovl > div {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }

    .desktop-error-ovl .btn {
        text-align: center;
    }

    .desktop-error-ovl .btn:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.menu-desktop-sup-container {
    width: 100vw;
    height: 50px;

    background: rgba(20, 20, 20, 0.8);
    position: fixed;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    z-index: 100;
}

.menu-desktop-sup-container .left {
    margin-left: 40px;
}

.menu-desktop-sup-container .left img {
    height: 30px;
}

.menu-desktop-sup-container .right {
    margin-right: 40px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.menu-desktop-sup-container .right .pesquisar {
    margin-right: 5vw;
}

.menu-desktop-sup-container .right .pesquisar form {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.menu-desktop-sup-container .right .pesquisar form input {
    font-weight: 300;
    font-size: 11px;
    padding-left: 8px;
    width: 23vw;
    height: 25px;
    outline: 0;
    border: 0;
    background: #ffffff;
    border-radius: 5px 0px 0px 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.menu-desktop-sup-container .right .pesquisar form input.error {
    border: 2px solid #e82f3e;
}

.menu-desktop-sup-container .right .pesquisar form button {
    background: #e82f3e;
    border-radius: 0px 5px 5px 0px;
    height: 25px;
    width: 30px;
    outline: 0;
    border: 0;
    color: white;
    font-size: 11px;
}

.menu-desktop-sup-container .right .menu {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.menu-desktop-sup-container .right .menu svg {
    cursor: pointer;
    font-size: 23px;
    color: white;
    margin-right: 8px;
}

.menu-desktop-sup-container .right .menu .plus {
    margin-right: 16px;
}

.menu-desktop-sup-container .right .menu .perfil {
    cursor: pointer;
    margin-left: 8px;

    border: 1px solid #ffffff;
    width: 25px;
    height: 25px;

    overflow: hidden;
    border-radius: 50%;
}

.menu-desktop-sup-container .right .menu .perfil img {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.menu-desktop-sup-container .right .menu .naologado {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    margin-left: 16px;
}

.menu-desktop-sup-container .right .menu .naologado .inv {
    margin-right: 12px;
    background: none;
    outline: 0;
    border: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: white;
}

.menu-desktop-sup-container .right .menu .naologado .sol {
    background: #e82f3e;
    border-radius: 5px;
    padding: 6px 8px;
    outline: 0;
    border: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: white;
}

.rc-dropdown-menu button {
    border: 0;
    outline: 0;
    text-align: right;
    font-size: 14px;
    background-color: transparent;
    padding: 5px 20px;
}

.desktop-notify-container {
    background-color: white;
    padding: 16px;
    border-radius: 5px;
    max-height: 400px;
    overflow-y: auto;
}

.desktop-notify-container::-webkit-scrollbar {
    width: 5px;
}

.desktop-notify-container h1 {
    font-size: 10px;
    margin-bottom: 4px;
    font-weight: 300;
}

.desktop-notify-container .not {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-notify-container .not:not(:last-child) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.192);
}

.desktop-notify-container .not img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
}

.desktop-notify-container .not h2 {
    font-size: 16px;
}

.desktop-pub-container {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 9999;
}

.desktop-pub-container > div {
    padding: 16px;
    background-color: white;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-pub-container > div .close {
    position: absolute;
    top: 6px;
    right: 18px;
    z-index: 999;
    background-color: transparent;
    border: 0;
    outline: 0;
    font-size: 24px;
}

.desktop-pub-container > div .left {
    min-width: 35vw;
    width: 500px;
    max-width: 90vw;
    height: 70vh;
}

.desktop-pub-container > div .left .photos-tog {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.desktop-pub-container > div .left .photos-tog .top {
    width: 100%;
    height: auto;
    position: absolute;
}

.desktop-pub-container > div .left .photos-tog .top {
    width: 100%;
    height: 70vh;
    position: relative;
    object-fit: contain;
    z-index: 999;
}

.desktop-pub-container > div .left .photos-tog .bottom {
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 0;
    object-fit: cover;
    filter: blur(3px) brightness(50%) contrast(110%);
    -webkit-filter: blur(3px) brightness(50%) contrast(110%);
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
}

.desktop-pub-container > div .right {
    min-width: 35vw;
    width: 400px;
    max-width: 45vw;
    height: 70vh;
    margin-left: 16px;
    padding-top: 8px;
}

.desktop-pub-container > div .right .row {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-pub-container > div .right .row .delete {
    font-size: 18px;
    color: #141414;
    cursor: pointer;
}

.desktop-pub-container > div .right .author {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-pub-container > div .right .author img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #fff;
    -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.4));
            filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.4));
}

.desktop-pub-container > div .right .author > div {
    margin-left: 8px;
}

.desktop-pub-container > div .right .author > div a {
    text-decoration: none;
    color: black;
}

.desktop-pub-container > div .right .author .nome {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
}

.desktop-pub-container > div .right .author .username {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    margin-top: 3px;
}

.desktop-pub-container > div .right .post {
    margin-top: 8px;
}

.desktop-pub-container > div .right .post h1 {
    font-size: 20px;
    line-height: 17px;
    color: #141414;
    font-weight: 300;
}

.desktop-pub-container > div .right .post p {
    margin-top: 8px;
    font-size: 15px;
    line-height: 18px;
    color: #141414;
}

.desktop-pub-container > div .right .pontos {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    margin-top: 24px;
    padding: 2px 5px;

    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.desktop-pub-container > div .right .pontos .esq {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-pub-container > div .right .pontos .esq p {
    font-size: 20px;
    margin-left: 4px;
}

.desktop-pub-container > div .right .pontos .esq svg {
    height: 25px;
    width: 25px;
}

.desktop-pub-container > div .right .pontos .dir {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-pub-container > div .right .pontos .dir svg {
    padding-top: 2px;
    height: 27px;
    width: 25px;
    margin-left: 3px;
}

.desktop-pub-container > div .right .pontos .dir p {
    font-size: 12px;
}

.desktop-pub-container > div .right .pontos .dir .deletar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 12px;
    margin-right: 16px;
    cursor: pointer;
}

.desktop-pub-container > div .right .comments {
    margin-top: 16px;
    padding: 0px 32px;
    overflow-y: auto;
}

.desktop-pub-container > div .right .comments form {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 16px;
}

.desktop-pub-container > div .right .comments form input {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-right: 4px;
    background-color: #ebebeb;
    border: 0;
    outline: 0;
    height: 40px;
    border-radius: 6px;
    padding-left: 16px;
    border: 1px solid #ababab;
}

.desktop-pub-container > div .right .comments form button {
    height: 40px;
    width: 40px;
    background-color: #e82f3e;
    border: 0;
    outline: 0;
    border-radius: 50%;
    font-size: 20px;
}

.desktop-pub-container > div .right .comments form button svg {
    width: 30px;
    height: 30px;
    margin-top: 4px;
}

.desktop-pub-container > div .right .comments .comment {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    margin-bottom: 8px;
}

.desktop-pub-container > div .right .comments .comment > div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-pub-container > div .right .comments .comment img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
}

.desktop-pub-container > div .right .comments .comment .name {
    font-size: 14px;
    font-weight: 700;
}

.desktop-pub-container > div .right .comments .comment .comm {
    font-size: 12px;
}

.black-ovl {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.6);
    z-index: 900;
}

.desktop-hero-container {
    width: 100vw;
    height: 350px;
    padding-left: 10vw;

    background-image: url(/static/media/bg-hero.a841d5a7.jpg);
    background-size: cover;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.desktop-hero-container p {
    font-size: 36px;
    font-weight: 600;
}

.desktop-hero-container p b {
    font-weight: 900;
    font-size: 40px;
}

.desktop-hero-container a {
    margin-top: 16px;
    background: #e82f3e;
    padding: 7px 50px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-decoration: none;
}

.desktop-listaposts-container {
    padding: 20px 60px;
}

.desktop-postonlist-container {
    border-radius: 8px;
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.25);
    width: 21vw;
    max-width: 370px;
    height: auto !important;
    background-color: #dedede;
    padding: 24px !important;

    margin-bottom: 32px;
}

@media (max-width: 1300px) {
    .desktop-postonlist-container {
        width: 50vw;
    }
}

.desktop-postonlist-container .slick-next {
    right: 4px;
    z-index: 500;
}

.desktop-postonlist-container .slick-prev {
    left: 4px;
    z-index: 500;
}

.desktop-postonlist-container .slick-dots {
    bottom: 4px;
    z-index: 500;
}

.desktop-postonlist-container .photos {
    position: relative;
}

.desktop-postonlist-container .photo-tog {
    position: relative;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-postonlist-container .photo-tog .top {
    object-fit: contain;
    z-index: 999;
}

.desktop-postonlist-container .photo-tog .bottom {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 998;
    object-fit: cover;
    filter: blur(3px) brightness(50%) contrast(110%);
    -webkit-filter: blur(3px) brightness(50%) contrast(110%);
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
}

.desktop-postonlist-container .photos {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.desktop-postonlist-container .photos .classif {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 5px 8px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 7px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
    z-index: 500;
}

.desktop-postonlist-container .photos .classif img {
    height: 27px;
    width: auto;
    margin-right: 5px;
}

.desktop-postonlist-container .photo-tog img {
    width: 100%;
    max-height: 350px;
}

.desktop-postonlist-container .autor {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.desktop-postonlist-container .autor .left {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-postonlist-container .autor .left img {
    width: 34px;
    height: 34px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
}

.desktop-postonlist-container .autor .left .name {
    font-size: 18px;
}

.desktop-postonlist-container .autor .left .username {
    font-size: 14px;
}

.desktop-postonlist-container .right {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-postonlist-container .right svg {
    height: 25px;
    width: 25px;
}

.desktop-postonlist-container .right p {
    font-size: 20px;
    margin-left: 4px;
}

.desktop-postonlist-container .ver-mais {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 8px 0;
}

.desktop-postonlist-container .ver-mais button {
    outline: 0;
    border: 0;
    padding: 7px 15px;
    background: #e82f3e;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    text-decoration: none;
}

.desktop-postonlist-container .desc {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    height: auto !important;
    overflow: hidden;
}

.desktop-postonlist-container .desc .place {
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}

.desktop-postonlist-container .desc .description {
    text-align: center;
    font-size: 12px;
}

.post-list-grid {
    grid-gap: 25px !important;
    grid-gap: 25px !important;
    gap: 25px !important;
}

.popup-pre-accept {
    width: 100%;
    height: 150px;

    position: fixed;
    bottom: 0;

    background-color: white;
    padding: 0 54px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    z-index: 999;
}

.popup-pre-accept .content {
    width: 150%;
    margin-bottom: 16px;
}

.popup-pre-accept .content p {
    text-align: center;
    font-size: 24px;
}

.popup-pre-accept .close {
    position: absolute;
    top: 8px;
    right: 8px;

    width: 20px;
    height: 20px;

    border: 1px solid #d4d4d4;
    border-radius: 150px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.popup-pre-accept .close svg {
    color: #818181;

    cursor: pointer;
}

.popup-pre-accept .buttons {
    display: -webkit-flex;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
}

.popup-pre-accept .buttons button{
    width: 100%;
    height: 40px;
    border-radius: 3px;
    outline: 0;
    border: 0;

    text-align: center;
    text-transform: uppercase;
    font-weight: 700;

    color: white;
    cursor: pointer;

}

.popup-pre-accept .buttons .grey-button {
    background: #818181;
}

.popup-pre-accept .buttons .red-button {
    background: #E82F3E;
}

.popup {
    position: fixed;

    max-width: 50%;
    width: 500px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    background-color: white;
    padding: 32px 16px 16px 16px;
    border-radius: 4px;

    z-index: 999;
}

.popup .slick-arrow::before {
    color: #E82F3E;
}

.popup .close {
    position: absolute;
    top: 8px;
    right: 8px;

    width: 20px;
    height: 20px;

    border: 1px solid #d4d4d4;
    border-radius: 150px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.popup .close svg {
    color: #818181;

    cursor: pointer;
}
.desktop-landingPage-container form img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;

    margin-bottom: 16px;
}

.desktop-landingPage-container form img.big {
    width: 300px;
    height: 300px;
}

.desktop-landingPage-container form .checkbox {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-landingPage-container form .checkbox input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-bottom: 0;
}

.label-error {
    color: brown;
}

.desktop-modal-termos {
    max-width: 750px;
    width: 70%;
    max-height: 80vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: scroll;
    padding: 16px;
    background-color: white;
    border-radius: 5px;
    z-index: 999;

    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.desktop-modal-termos .close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}

.desktop-landingPage-container form img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;

    margin-bottom: 16px;
}

.desktop-landingPage-container form img.big {
    width: 300px;
    height: 300px;
}

.desktop-landingPage-container form .checkbox {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-landingPage-container form .checkbox input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-bottom: 0;
}

.label-error {
    color: brown;
}

.desktop-landingPage-container form img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;

    margin-bottom: 16px;
}

.desktop-landingPage-container form img.big {
    width: 300px;
    height: 300px;
}

.desktop-landingPage-container form .checkbox {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-landingPage-container form .checkbox input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-bottom: 0;
}

.label-error {
    color: brown;
}

.desktop-profile-container {
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;

    padding-top: 80px;
}

.desktop-profile-container .row {
    width: 80%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-profile-container .row:first-child {
    margin-bottom: 30px;
}

.desktop-profile-container .row .left {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-profile-container .row .left img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.desktop-profile-container .row .left .name {
    margin-left: 32px;
}

.desktop-profile-container .row .left .name h1 {
    font-size: 32px;
    font-weight: 400;
}

.desktop-profile-container .row .left .name h2 {
    font-size: 20px;
    font-weight: 400;
}

.desktop-profile-container .row .right {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-profile-container .row .right .contador {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-profile-container .row .right .contador:not(:last-child) {
    margin-right: 30px;
}

.desktop-profile-container .row .right .contador h3 {
    font-size: 26px;
    font-weight: 700;
}

.desktop-profile-container .row .right .contador p {
    font-size: 16px;
    font-weight: 400;
}

.desktop-profile-container .row .left2 p {
    font-size: 20px;
    max-width: 350px;
}

.desktop-profile-container .row .right2 button {
    outline: 0;
    border: 0;
    padding: 10px 70px;
    background: #e82f3e;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    text-decoration: none;
}

.desktop-profile-container .row .right2 .btn-meu {
    margin-top: 16px;
}

.desktop-profile-container .row .right2 .btn-meu button + button {
    background: #ea662d;

    margin-left: 16px;
    text-align: center;
    padding: 10px 20px;
}

.desktop-profile-container .row .right2 .seguindo {
    background: #f3bcc0;
    color: #141414;
}

.icon-close-profile {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 20px;
    height: 20px;
    background: white;
    color: black;
    border-radius: 50%;
    cursor: pointer;
}

.icon-close-profile {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 20px;
    height: 20px;
    background: white;
    color: black;
    border-radius: 50%;
    cursor: pointer;
}

.desktop-landingPage-container form h5 {
    text-align: center;
    width: 80%;
    margin-top: 16px;
    margin-bottom: 8px;
}

.desktop-new-post-container {
    width: 400px;
    position: absolute;
    background-color: white;
    padding: 24px 0px;
    border-radius: 5px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;

    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.desktop-new-post-container .x {
    position: absolute;
    left: 4px;
    top: 4px;
}

.desktop-new-post-container .x {
    background-color: black;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    height: 24px;
    width: 24px;
}

.desktop-new-post-container .x svg {
    font-size: 24px;
    color: rgb(255, 255, 255);
    z-index: 15;
}
.desktop-new-post-container .slick-slider {
    width: 90% !important;
}

.desktop-no-photo-container {
    height: 370px;
    width: 100%;
    background: url(/static/media/bg-field.66eb1f97.jpg);
    background-size: cover;
    background-position: center;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-no-photo-container button {
    background-color: #e82f3f93;
    padding: 12px;
    border: 0;
    outline: 0;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.slick-next {
    right: 4px;
    z-index: 999;
}

.slick-prev {
    left: 4px;
    z-index: 999;
}

.desktop-with-photo-container {
    height: 370px;

    width: 100%;

    position: relative;

    overflow: hidden;
}

.desktop-with-photo-container .image {
    height: 370px;

    width: 100%;

    object-fit: contain;

    position: absolute;
}

.desktop-with-photo-container .delete {
    position: absolute;
    bottom: 6px;
    right: 6px;
    cursor: pointer;

    color: white;
    background-color: black;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    z-index: 999;
}

.desktop-form-newpost-container {
    margin-top: 24px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-form-newpost-container .group {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.desktop-form-newpost-container .group input,
.desktop-form-newpost-container .group textarea,
.desktop-form-newpost-container .group .css-2b097c-container {
    width: 100%;
}

.desktop-form-newpost-container .group .css-2b097c-container {
    margin-right: 4px;
    margin-left: 0px;
}

.desktop-form-newpost-container
    .group
    .css-2b097c-container
    + .css-2b097c-container {
    margin-right: 0px;
    margin-left: 4px;
}

.desktop-form-newpost-container .group .inp {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    min-height: 38px;
    outline: 0 !important;
    padding: 6px 8px;
    font-size: 16px;
    margin-top: 16px;
}

.desktop-form-newpost-container .group .txt {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    min-height: 114px;
    outline: 0 !important;
    padding: 6px 8px;
    font-size: 16px;
    margin-top: 16px;
}

.desktop-form-newpost-container .btn {
    margin-top: 24px;
    width: 90%;
}

.desktop-form-newpost-container .btn button {
    width: 100%;
    outline: 0;
    border: 0;
    height: 38px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #e82f3e;
    font-size: 16px;
    font-weight: 600;
    color: white;
}

.mobile-landingPage-container {
    width: 100vw;
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url(/static/media/lp-desktop.b48bb11a.jpg);
    background-size: cover;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-landingPage-container .texts {
    min-width: 400px;
    width: 30vw;
    text-align: center;
}

.mobile-landingPage-container .texts img {
    width: 30%;
}

.mobile-landingPage-container .texts h1 {
    color: white;
    text-shadow: 0px 3px 3px #000;
    text-align: center;

    margin-top: 8px;
}

.mobile-landingPage-container .texts h2 {
    color: white;
    text-shadow: 0px 3px 3px #000;
    text-align: center;
}

.mobile-landingPage-container form {
    min-width: 300px;
    width: 23vw;
    margin-top: 48px;
    background-color: white;
    border-radius: 10px;

    padding: 15px 5px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-landingPage-container form p {
    width: 90%;
    color: #575757;
    font-size: 10px;
    text-align: left;
    margin-bottom: 4px;
    font-weight: 300;
}

.mobile-landingPage-container form p.error {
    font-size: 12px;
    text-align: center;
    color: #e82f3e;
}

.mobile-landingPage-container form textarea {
    padding-left: 16px;
    padding: 8px;
    width: 90%;
    height: 40px;
    margin-bottom: 8px;
    border: 0;
    font-size: 14px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.mobile-landingPage-container form input {
    padding-left: 16px;
    width: 90%;
    height: 40px;
    margin-bottom: 8px;
    border: 0;
    font-size: 14px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.mobile-landingPage-container form input.error,
.mobile-landingPage-container form textarea.error {
    border: 1px solid #e82f3e;
}

.mobile-landingPage-container form button {
    margin-top: 16px;
    outline: 0;
    border: 0;
    width: 90%;
    height: 40px;
    background: #e82f3e;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-decoration: none;
}

.mobile-landingPage-container form a {
    font-weight: 300;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    margin-top: 16px;
    margin-right: 0;
    color: #575757;
}

.mobile-landingPage-container form a + a {
    margin-top: 4px;
}

.mobile-landingPage-container form h5 {
    text-align: center;
    width: 80%;
    margin-top: 16px;
    margin-bottom: 8px;
}

.mobile-landingPage-container .eye {
    position: absolute;
    right: 24px;
    top: 47%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.desktop-landingPage-container .eye {
    position: absolute;
    right: 36px;
    top: 47%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.desktop-loading-ovl {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(
        to right bottom,
        rgba(1, 1, 1, 0.8),
        rgba(1, 1, 1, 0.6)
    );
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 999;
}
.desktop-loading-ovl p {
    padding-top: 50px;
    color: white;
    font-size: 20px;
}

.mobile-landingPage-container form img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;

    margin-bottom: 16px;
}

.mobile-landingPage-container form img.big {
    width: 300px;
    height: 300px;
}

.mobile-landingPage-container.cad {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-size: cover;
    padding: 48px 0;
}

.mobile-landingPage-container form .checkbox {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-landingPage-container form .checkbox input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-bottom: 0;
}

.label-error {
    color: brown;
}

.mobile-landingPage-container form img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;

    margin-bottom: 16px;
}

.mobile-landingPage-container form img.big {
    width: 300px;
    height: 300px;
}

.mobile-landingPage-container.cad {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-size: cover;
    padding: 48px 0;
}

.mobile-landingPage-container form .checkbox {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-landingPage-container form .checkbox input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-bottom: 0;
}

.label-error {
    color: brown;
}

.modal-termos {
    max-width: 350px;
    width: 90%;
    max-height: 80vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: scroll;
    padding: 16px;
    background-color: white;
    border-radius: 5px;
    z-index: 999;

    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.modal-termos .close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}

.mobile-landingPage-container form img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;

    margin-bottom: 16px;
}

.mobile-landingPage-container form img.big {
    width: 300px;
    height: 300px;
}

.mobile-landingPage-container.cad {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-size: cover;
    padding: 48px 0;
    min-height: 100vh;
}

.mobile-landingPage-container form .checkbox {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-landingPage-container form .checkbox input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-bottom: 0;
}

.label-error {
    color: brown;
}

.bg_full {
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/lp-desktop.b48bb11a.jpg);
    background-size: cover;
}

.menu-sup {
    position: fixed;
    z-index: 60;
    top: 32px;

    width: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.menu-sup a {
    font-size: 16px;
    letter-spacing: 2px;
    color: white;
    text-decoration: none;
}

.menu-sup p {
    font-size: 10px;
    color: white;
    margin: 0 8px;
}
.menu-inferior-container {
    position: fixed;
    bottom: 0;
    z-index: 90;

    width: 100vw;
    height: 60px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;

    background: #ebebeb;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
}

.menu-inferior-container.no {
    position: fixed;
    bottom: 0;
    z-index: 90;

    width: 100vw;
    height: 60px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;

    background: transparent;
    box-shadow: none;
}

.menu-inferior-container a .ico-pqn {
    color: black;
    font-size: 23px;
    transition: all 0.5s;
}

.menu-inferior-container a .ico-pqn.active {
    padding-bottom: 4px;
    border-bottom: 2px solid;
    font-size: 29px;
}

.menu-inferior-container a .ico-grn {
    color: black;
    font-size: 35px;
}

.menu-inferior-container.no a .ico-pqn,
.menu-inferior-container.no a .ico-grn {
    color: white;
}

.desktop-error-ovl {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(
        to right bottom,
        rgba(1, 1, 1, 0.8),
        rgba(1, 1, 1, 0.6)
    );
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.desktop-error-ovl p {
    padding-top: 15px;
    color: white;
    font-size: 20px;
    text-align: center;
}
.desktop-error-ovl h1 {
    font-size: 50px !important;
    color: white;
    font-size: 20px;
}

.desktop-error-ovl > div {
    margin-top: 48px;
    display: -webkit-flex;
    display: flex;
}

.desktop-error-ovl .btn {
    background: #e82f3e;
    padding: 10px 25px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-decoration: none;
}

.desktop-error-ovl .btn:not(:last-child) {
    margin-right: 15px;
}

@media only screen and (max-width: 950px) {
    .desktop-error-ovl h1 {
        font-size: 30px !important;
    }

    .desktop-error-ovl > div {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }

    .desktop-error-ovl .btn {
        text-align: center;
    }

    .desktop-error-ovl .btn:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.mobile-listaposts-container {
    background-color: black;
}

.mobile-listaposts-container .menu-sup {
    position: fixed;
    z-index: 60;
    top: 32px;

    width: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-listaposts-container .menu-sup a {
    font-size: 16px;
    letter-spacing: 2px;
    color: white;
    text-decoration: none;
}

.mobile-listaposts-container .menu-sup p {
    font-size: 10px;
    color: white;
    margin: 0 8px;
}

.mobile-postonlist-container {
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
}

.mobile-postonlist-container .photos {
    width: 100vw;
    height: 100%;
    background-color: black;
    position: relative;
}

.mobile-postonlist-container .photos .photo {
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    overflow: hidden;
}

.mobile-postonlist-container .photos .top {
    top: 0;
    position: absolute;
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    object-fit: contain;
    z-index: 50;
}

.mobile-postonlist-container .photos .bot {
    top: 0;
    position: absolute;
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
    z-index: 40;
    filter: blur(5px) brightness(50%) contrast(110%);
    -webkit-filter: blur(5px) brightness(50%) contrast(110%);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.mobile-postonlist-container .ovl .ovl-top {
    width: 100vw;
    height: 37vh;
    position: absolute;
    top: 0;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 100%
        ),
        transparent;
    background-blend-mode: multiply;
    z-index: 53;
}

.mobile-postonlist-container .ovl .ovl-bot {
    width: 100vw;
    height: 50vh;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.9) 100%
        ),
        transparent;
    background-blend-mode: multiply;
    z-index: 53;
}

.mobile-postonlist-container .resume {
    width: 100%;
    z-index: 55;
    position: absolute;
    bottom: 70px;

    padding: 0 24px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.mobile-postonlist-container .resume .left {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-right: 16px;
}

.mobile-postonlist-container .resume .left .first {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-postonlist-container .resume .left .first a {
    color: white;
    text-decoration: none;
    font-size: 14px;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left .first .pontos {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    font-size: 18px;
}

.mobile-postonlist-container .resume .left .first .pontos svg {
    margin-right: 4px;
}

.mobile-postonlist-container .resume .left h1 {
    margin-top: 2px;

    font-size: 20px;
    font-weight: 500;
    color: white;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left p {
    margin-top: 2px;

    font-size: 13px;
    font-weight: 500;
    color: white;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left p b {
    text-decoration: underline;
}

.mobile-postonlist-container .resume .left p.big {
    font-size: 18px;
}

.mobile-postonlist-container .counter {
    position: absolute;
    z-index: 60;
    top: 32px;
    right: 20px;
    padding: 4px;
    border-radius: 5px;
    background-color: rgba(140, 140, 140, 0.6);
    color: white;
    font-size: 12px;
}

.mobile-postonlist-container .resume .right {
    -webkit-flex: 0 0 12%;
            flex: 0 0 12%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.mobile-postonlist-container .resume .right svg {
    width: 40px;
    height: 40px;

    color: white;

    margin-bottom: 12px;
}

.mobile-postonlist-container .resume .right img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid white;
    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
}

.lista-comentarios-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.lista-comentarios-container .comm-ind {
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.lista-comentarios-container .comm-ind + .comm-ind {
    margin-top: 12px;
}

.lista-comentarios-container .comm-ind .left {
    margin-left: 16px;
}

.lista-comentarios-container .comm-ind .left img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
}

.lista-comentarios-container .comm-ind .right {
    margin-left: 12px;
}

.lista-comentarios-container .comm-ind .right h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
}

.lista-comentarios-container .comm-ind .right p {
    margin-top: 4px;
    width: 70vw;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
}

.lista-comentarios-container .comm-ind .delete-comm {
    margin-top: 8px;
}

.comentarios-container {
    width: 100vw;
    height: 85vh;
    position: fixed;
    bottom: 0;
    z-index: 999;
    background-color: white;
    border-radius: 15px 15px 0px 0px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.4);
}

.comentarios-container .head {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 16px;
    margin-top: 8px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.comentarios-container .head p {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
}

.comentarios-container .head .none {
    margin-left: 16px;
    opacity: 0;
}

.comentarios-container .head .x {
    margin-right: 16px;
    font-size: 18px;
}

.comentarios-container .lista {
    padding-top: 8px;
    padding-bottom: 16px;
    overflow-y: auto;
    height: 72vh;
}

.comentarios-container .inputs {
    margin-top: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    height: 40px;
    width: 100%;
    bottom: 16px;
    position: absolute;
}

.comentarios-container .inputs .right {
    margin: 16px;
    width: 100%;
}

.comentarios-container .inputs .right form {
    display: -webkit-flex;
    display: flex;
}

.comentarios-container .inputs .right input {
    -webkit-flex: 1 1;
            flex: 1 1;
    background: #ebebeb;
    border: 1px solid #ababab;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    padding-left: 8px;
}

.comentarios-container .inputs .right button {
    background: #e82f3e;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50%;
    margin-left: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.comentarios-container .inputs .right button svg {
    font-size: 32px;
}

.opacidade {
    top: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 600;
    background-color: rgba(0, 0, 0, 0.7);
}

.comentarios-container .deslogado {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.comentarios-container .deslogado p {
    margin-left: 16px;
    width: 60%;
    font-size: 18px;
}

.comentarios-container .deslogado a {
    margin-right: 16px;
    background-color: #e82f3e;
    color: white;
    text-decoration: none;
    font-size: 20px;
    padding: 8px 12px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.container-like {
    position: fixed;
    z-index: 50;
    padding: 4px 8px;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    bottom: 135px;
    right: 20px;
    transition: all 2s;
    -webkit-transition: all 2s;
    -moz-transition: all 2s;
    -ms-transition: all 2s;
    -o-transition: all 2s;
}

.container-like svg {
    font-size: 42px;
    margin-left: 0;
}

.container-like span {
    margin-left: 2px;
    margin-right: 2px;
}

.ovl-container-like {
    z-index: 40;
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.mobile-popup-pre-accept {

    width: 100%;

    height: 150px;



    position: fixed;

    bottom: 0;



    background-color: white;



    display: -webkit-flex;



    display: flex;

    -webkit-flex-direction: column;

            flex-direction: column;

    -webkit-justify-content: center;

            justify-content: center;

    -webkit-align-items: center;

            align-items: center;



    z-index: 999;

}



.mobile-popup-pre-accept .content {

    width: 70%;

    margin-bottom: 16px;

}



.mobile-popup-pre-accept .content p {

    text-align: center;

}



.mobile-popup-pre-accept .close {

    position: absolute;

    top: 8px;

    right: 8px;



    width: 20px;

    height: 20px;



    border: 1px solid #d4d4d4;

    border-radius: 150px;



    display: -webkit-flex;



    display: flex;

    -webkit-justify-content: center;

            justify-content: center;

    -webkit-align-items: center;

            align-items: center;

}



.mobile-popup-pre-accept .close svg {

    color: #818181;



    cursor: pointer;

}



.mobile-popup-pre-accept .buttons {

    display: -webkit-flex;

    display: flex;

    grid-gap: 8px;

    gap: 8px;

}



.mobile-popup-pre-accept .buttons button{

    width: 40vw;

    height: 40px;

    border-radius: 3px;

    outline: 0;

    border: 0;



    text-align: center;

    text-transform: uppercase;

    font-weight: 700;



    color: white;



}



.mobile-popup-pre-accept .buttons .grey-button {

    background: #818181;

}



.mobile-popup-pre-accept .buttons .red-button {

    background: #E82F3E;

}



.mobile-popup {

    position: fixed;



    width: 90%;

    left: 50%;

    top: 50%;

    -webkit-transform: translate(-50%, -50%);

            transform: translate(-50%, -50%);



    background-color: white;

    padding: 16px 16px 16px 16px;

    border-radius: 4px;



    z-index: 999;

}



.mobile-popup .slick-arrow::before {

    color: #E82F3E;

}



.mobile-popup .close {

    margin-bottom: 8px;

    width: 100%;

    display: -webkit-flex;

    display: flex;

    -webkit-justify-content: space-between;

            justify-content: space-between;

    -webkit-align-items: center;

            align-items: center;

}


.mobile-popup .close .buttons {
    display: -webkit-flex;
    display: flex;
    grid-gap: 8px;
    gap: 8px;

}

.mobile-popup .close .buttons button {
    background-color: #E82F3E;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    outline: 0;
    border: 0;
}


.mobile-popup .close svg {

    color: #818181;

    border: 1px solid #d4d4d4;

    border-radius: 150px;

    cursor: pointer;

}
.header-container {
    position: fixed;

    width: 100vw;
    height: 50px;
    background: #ebebeb;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    padding: 0 16px;
}

.header-container .left svg {
    font-size: 22px;
    color: black;
}

.header-container .right a img {
    font-size: 22px;
    color: black;
}

.header-container .middle {
    justify-self: center;
    position: relative;
}

.header-container .middle input {
    background-color: white;
    width: 80vw;
    height: 26px;
    padding-left: 8px;
    border-radius: 3px 0px 0px 3px;
    border-left: 1px solid #878787;
    border-top: 1px solid #878787;
    border-bottom: 1px solid #878787;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.header-container .middle button {
    color: #141414;
    margin-top: 2px;
    margin-left: -9vw;
    position: absolute;
    background-color: transparent;
    width: 10vw;
    height: 26px;
    border: 0;
}

.header-container .middle img {
    height: 35px;
}

.resume-perfil-container {
    padding-top: 66px;

    display: -webkit-flex;

    display: flex;

    -webkit-flex-direction: column;

            flex-direction: column;

    -webkit-align-items: center;

            align-items: center;
}

.resume-perfil-container .photo {
    width: 126px;

    height: 126px;

    overflow: hidden;

    border-radius: 50%;

    -webkit-border-radius: 50%;

    -moz-border-radius: 50%;

    -ms-border-radius: 50%;

    -o-border-radius: 50%;

    border: #e82f3e 2px solid;
}

.resume-perfil-container .photo img {
    width: 126px;

    height: 126px;

    object-fit: cover;
}

.resume-perfil-container .basic {
    margin-top: 4px;

    width: 100%;
}

.resume-perfil-container .basic h1 {
    font-weight: normal;

    font-size: 18px;

    line-height: 25px;

    text-align: center;
}

.resume-perfil-container .basic h2 {
    font-weight: normal;

    font-size: 15px;

    line-height: 20px;

    text-align: center;
}

.resume-perfil-container .numeros {
    margin-top: 24px;

    display: -webkit-flex;

    display: flex;

    -webkit-justify-content: space-between;

            justify-content: space-between;

    -webkit-align-items: center;

            align-items: center;

    width: 70%;
}

.resume-perfil-container .numeros .numero {
    display: -webkit-flex;
    display: flex;

    -webkit-flex-direction: column;

            flex-direction: column;

    -webkit-align-items: center;

            align-items: center;

    -webkit-justify-content: center;

            justify-content: center;
}

.resume-perfil-container .numeros .numero h3 {
    font-weight: bold;

    font-size: 18px;

    line-height: 21px;

    text-align: center;
}

.resume-perfil-container .numeros .numero p {
    font-weight: normal;

    font-size: 15px;

    line-height: 18px;

    text-align: center;
}

.resume-perfil-container .btn {
    margin-top: 16px;
}

.resume-perfil-container .btn.seg button {
    background: #f3bcc0;

    color: #141414;
}

.resume-perfil-container .btn button {
    background: #e82f3e;

    border-radius: 3px;

    border: 0;

    width: 157px;

    height: 43px;

    font-weight: 600;

    font-size: 17px;

    line-height: 20px;

    text-transform: uppercase;

    color: white;

    transition: all 0.5s;
}

.resume-perfil-container .btn-meu button {
    background: #e82f3e;

    border-radius: 3px;

    border: 0;

    width: 113px;

    height: 43px;

    font-weight: 600;

    font-size: 17px;

    line-height: 20px;

    text-transform: uppercase;

    color: white;

    transition: all 0.5s;
}

.resume-perfil-container .btn-meu {
    margin-top: 16px;
}

.resume-perfil-container .btn-meu button + button {
    background: #ea662d;

    width: 45px;

    margin-left: 16px;
}

.resume-perfil-container .resumo {
    margin-top: 16px;

    width: 70%;
}

.resume-perfil-container .resumo p {
    font-weight: normal;

    font-size: 15px;

    line-height: 20px;

    text-align: center;

    color: #000000;
}

.social-icons-share {
    margin: 16px;
    display: -webkit-flex;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

.entry-photos-container {
    width: 100vw;
    height: 45px;
    background: #ebebeb;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 16px;
}

.entry-photos-container .icon {
    font-size: 28px;
    color: #e82f3e;
    border-bottom: #e82f3e solid 3px;
    padding-bottom: 3px;
}

.mob-post-list-grid {
    margin-top: 5px;
    display: -webkit-flex;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
}

.mob-post-list-grid_column img {
    width: 100%;
}

.is-loading {
    margin-top: 60px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.perfil-container {
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 100vh;
}

.is-loading {
    margin-top: 60px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.notificacoes-container {    padding-top: 66px;    overflow-y: auto;    display: -webkit-flex;    display: flex;    -webkit-flex-direction: column;    flex-direction: column;    -webkit-align-items: flex-start;    align-items: flex-start;    margin-bottom: 70px;}.notificacoes-container .dia {    display: -webkit-flex;    display: flex;    -webkit-flex-direction: column;    flex-direction: column;}.notificacoes-container .dia + .dia {    margin-top: 24px;}.notificacoes-container .dia h2 {    font-weight: normal;    font-size: 14px;    line-height: 15px;    margin-left: 16px;}.notificacoes-container .dia .noti {    margin-left: 16px;    margin-top: 16px;    display: -webkit-flex;    display: flex;    -webkit-align-items: center;    align-items: center;}.notificacoes-container .dia .noti .photo {    width: 50px;    height: 50px;    overflow: hidden;    border-radius: 50%;    display: -webkit-flex;    display: flex;    -webkit-justify-content: center;    justify-content: center;    -webkit-align-items: center;    align-items: center;}.notificacoes-container .dia .noti .photo img {    width: 50px;    height: 50px;        object-fit: cover;}.notificacoes-container .dia .resume {    display: -webkit-flex;    display: flex;    -webkit-flex-direction: column;    flex-direction: column;    margin-left: 8px;    color: black;    text-decoration: none;}.notificacoes-container .dia .resume h3 {    font-weight: normal;    font-size: 15px;    line-height: 17px;}.notificacoes-container .dia .resume p {    font-weight: normal;    font-size: 13px;    line-height: 15px;    margin-top: 4px;}

.mobile-listaposts-container {
    background-color: black;
}

.mobile-listaposts-container .menu-sup {
    position: fixed;
    z-index: 60;
    top: 32px;

    width: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-listaposts-container .menu-sup a {
    font-size: 16px;
    letter-spacing: 2px;
    color: white;
    text-decoration: none;
}

.mobile-listaposts-container .menu-sup p {
    font-size: 10px;
    color: white;
    margin: 0 8px;
}

.mobile-postonlist-container {
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
}

.mobile-postonlist-container .photos {
    width: 100vw;
    height: 100%;
    background-color: black;
    position: relative;
}

.mobile-postonlist-container .photos .photo {
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    overflow: hidden;
}

.mobile-postonlist-container .photos .top {
    top: 0;
    position: absolute;
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    object-fit: contain;
    z-index: 50;
}

.mobile-postonlist-container .photos .bot {
    top: 0;
    position: absolute;
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
    z-index: 40;
    filter: blur(5px) brightness(50%) contrast(110%);
    -webkit-filter: blur(5px) brightness(50%) contrast(110%);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.mobile-postonlist-container .ovl .ovl-top {
    width: 100vw;
    height: 37vh;
    position: absolute;
    top: 0;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 100%
        ),
        transparent;
    background-blend-mode: multiply;
    z-index: 53;
}

.mobile-postonlist-container .ovl .ovl-bot {
    width: 100vw;
    height: 50vh;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.9) 100%
        ),
        transparent;
    background-blend-mode: multiply;
    z-index: 53;
}

.mobile-postonlist-container .resume {
    width: 100%;
    z-index: 55;
    position: absolute;
    bottom: 70px;

    padding: 0 24px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.mobile-postonlist-container .resume .left {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-right: 16px;
}

.mobile-postonlist-container .resume .left .first {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-postonlist-container .resume .left .first a {
    color: white;
    text-decoration: none;
    font-size: 14px;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left .first .pontos {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    font-size: 18px;
}

.mobile-postonlist-container .resume .left .first .pontos svg {
    margin-right: 4px;
}

.mobile-postonlist-container .resume .left h1 {
    margin-top: 2px;

    font-size: 20px;
    font-weight: 500;
    color: white;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left p {
    margin-top: 2px;

    font-size: 13px;
    font-weight: 500;
    color: white;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left p b {
    text-decoration: underline;
}

.mobile-postonlist-container .resume .left p.big {
    font-size: 18px;
}

.mobile-postonlist-container .counter {
    position: absolute;
    z-index: 60;
    top: 32px;
    right: 20px;
    padding: 4px;
    border-radius: 5px;
    background-color: rgba(140, 140, 140, 0.6);
    color: white;
    font-size: 12px;
}

.mobile-postonlist-container .resume .right {
    -webkit-flex: 0 0 12%;
            flex: 0 0 12%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.mobile-postonlist-container .resume .right svg {
    width: 40px;
    height: 40px;

    color: white;

    margin-bottom: 12px;
}

.mobile-postonlist-container .resume .right img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid white;
    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
}

.mobile-postonlist-container .close {
    position: absolute;
    top: 32px;
    left: 20px;
    color: white;
    z-index: 999;
}

.mobile-postonlist-container .close svg {
    width: 25px;
    height: 25px;
}

.mobile-postonlist-container .apagar {
    position: absolute;
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    color: white;
    z-index: 999;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-postonlist-container .apagar p {
    font-size: 12px;
    margin-bottom: 4px;
}

.mobile-postonlist-container .apagar svg {
    width: 25px;
    height: 25px;
}

.warning-container {
    z-index: 999;
    position: absolute;

    background-color: white;
    width: 80vw;
    padding: 16px;
    border-radius: 5px;

    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.warning-container p {
    text-align: center;
}

.warning-container > div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 8px;
}

.warning-container button {
    width: 40%;
    margin: 4px;
    outline: 0;
    border: 0;
    padding: 5px 10px;
    border-radius: 3px;
}

.warning-container button.sim {
    background-color: rgba(165, 0, 0, 0.637);
    font-weight: 700;
    border: 1px solid rgba(75, 0, 0, 0.637);
}

.warning-container button.nao {
    background-color: rgb(219, 219, 219);
    border: 1px solid grey;
}

.bg_full {
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/lp-desktop.b48bb11a.jpg);
    background-size: cover;
}

.mobile-listaposts-container {
    background-color: black;
}

.mobile-listaposts-container .menu-sup {
    position: fixed;
    z-index: 60;
    top: 32px;

    width: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-listaposts-container .menu-sup a {
    font-size: 16px;
    letter-spacing: 2px;
    color: white;
    text-decoration: none;
}

.mobile-listaposts-container .menu-sup p {
    font-size: 10px;
    color: white;
    margin: 0 8px;
}

.mobile-postonlist-container {
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
}

.mobile-postonlist-container .photos {
    width: 100vw;
    height: 100%;
    background-color: black;
    position: relative;
}

.mobile-postonlist-container .photos .photo {
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    overflow: hidden;
}

.mobile-postonlist-container .photos .top {
    top: 0;
    position: absolute;
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    object-fit: contain;
    z-index: 50;
}

.mobile-postonlist-container .photos .bot {
    top: 0;
    position: absolute;
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
    z-index: 40;
    filter: blur(5px) brightness(50%) contrast(110%);
    -webkit-filter: blur(5px) brightness(50%) contrast(110%);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.mobile-postonlist-container .ovl .ovl-top {
    width: 100vw;
    height: 37vh;
    position: absolute;
    top: 0;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 100%
        ),
        transparent;
    background-blend-mode: multiply;
    z-index: 53;
}

.mobile-postonlist-container .ovl .ovl-bot {
    width: 100vw;
    height: 50vh;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.9) 100%
        ),
        transparent;
    background-blend-mode: multiply;
    z-index: 53;
}

.mobile-postonlist-container .resume {
    width: 100%;
    z-index: 55;
    position: absolute;
    bottom: 70px;

    padding: 0 24px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.mobile-postonlist-container .resume .left {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-right: 16px;
}

.mobile-postonlist-container .resume .left .first {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-postonlist-container .resume .left .first a {
    color: white;
    text-decoration: none;
    font-size: 14px;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left .first .pontos {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    font-size: 18px;
}

.mobile-postonlist-container .resume .left .first .pontos svg {
    margin-right: 4px;
}

.mobile-postonlist-container .resume .left h1 {
    margin-top: 2px;

    font-size: 20px;
    font-weight: 500;
    color: white;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left p {
    margin-top: 2px;

    font-size: 13px;
    font-weight: 500;
    color: white;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left p b {
    text-decoration: underline;
}

.mobile-postonlist-container .resume .left p.big {
    font-size: 18px;
}

.mobile-postonlist-container .counter {
    position: absolute;
    z-index: 60;
    top: 32px;
    right: 20px;
    padding: 4px;
    border-radius: 5px;
    background-color: rgba(140, 140, 140, 0.6);
    color: white;
    font-size: 12px;
}

.mobile-postonlist-container .resume .right {
    -webkit-flex: 0 0 12%;
            flex: 0 0 12%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.mobile-postonlist-container .resume .right svg {
    width: 40px;
    height: 40px;

    color: white;

    margin-bottom: 12px;
}

.mobile-postonlist-container .resume .right img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid white;
    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
}

.mobile-postonlist-container .classif {
    background-color: rgba(255, 255, 255, 0.685);
    position: absolute;
    z-index: 999;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 5px 10px;

    border-radius: 8px;

    top: 10vh;
    right: 16px;
}

.mobile-postonlist-container .classif p {
    font-size: 20px;
}

.no-more {
    width: 100vw;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    color: white;

    text-align: center;
}

.no-more p {
    margin: 16px;
}

.no-more button {
    margin-top: 16px;
    outline: 0;
    border: 0;
    padding: 10px 35px;
    background: #e82f3e;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-decoration: none;
}

.bg_full {
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/lp-desktop.b48bb11a.jpg);
    background-size: cover;
}

.post-list-grid {
    margin-top: 5px;
    display: -webkit-flex;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
}

.post-list-grid_column img {
    width: 100%;
}

.is-loading {
    margin-top: 60px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.bg_full {
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/lp-desktop.b48bb11a.jpg);
    background-size: cover;
}

.new-post-container .x {
    position: absolute;
    left: 4px;
    top: 4px;
}

.new-post-container .x {
    background-color: black;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    height: 24px;
    width: 24px;
}

.new-post-container .x svg {
    font-size: 24px;
    color: rgb(255, 255, 255);
    z-index: 15;
}

.no-photo-container {
    height: 100vw;
    width: 100vw;
    background: url(/static/media/bg-field.66eb1f97.jpg);
    background-size: cover;
    background-position: center;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.no-photo-container button {
    background-color: #e82f3f93;
    padding: 12px;
    border: 0;
    outline: 0;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.with-photo-container {
    height: 100vw;
    width: 100vw;
    position: relative;
    overflow: hidden;
}

.with-photo-container .image {
    height: 100vw;
    width: 100vw;
    object-fit: contain;
    position: absolute;
}

.with-photo-container .blur {
    height: 100vw;
    width: 100vw;
    object-fit: cover;
    position: absolute;
    filter: blur(2px);
    -webkit-filter: blur(2px);
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    filter: brightness(50%) contrast(110%);
    -webkit-filter: brightness(50%) contrast(110%);
}

.form-newpost-container {
    margin-top: 24px;
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.form-newpost-container .group {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.form-newpost-container .group input,
.form-newpost-container .group textarea,
.form-newpost-container .group .css-2b097c-container {
    width: 100%;
}

.form-newpost-container .group .css-2b097c-container {
    margin-right: 4px;
    margin-left: 0px;
}

.form-newpost-container .group .css-2b097c-container + .css-2b097c-container {
    margin-right: 0px;
    margin-left: 4px;
}

.form-newpost-container .group .inp {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    min-height: 38px;
    outline: 0 !important;
    padding: 6px 8px;
    font-size: 16px;
    margin-top: 16px;
}

.form-newpost-container .group .txt {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    min-height: 114px;
    outline: 0 !important;
    padding: 6px 8px;
    font-size: 16px;
    margin-top: 16px;
}

.form-newpost-container .btn {
    margin-top: 24px;
    width: 90%;
}

.form-newpost-container .btn button {
    width: 100%;
    outline: 0;
    border: 0;
    height: 38px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #e82f3e;
    font-size: 16px;
    font-weight: 600;
    color: white;
}

