.perfil-container {
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 100vh;
}

.is-loading {
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
}
