.bg_full {
    width: 100%;
    height: 100vh;
    background-image: url("../../../assets/lp-desktop.jpg");
    background-size: cover;
}

.menu-sup {
    position: fixed;
    z-index: 60;
    top: 32px;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-sup a {
    font-size: 16px;
    letter-spacing: 2px;
    color: white;
    text-decoration: none;
}

.menu-sup p {
    font-size: 10px;
    color: white;
    margin: 0 8px;
}