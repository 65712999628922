.header-container {
    position: fixed;

    width: 100vw;
    height: 50px;
    background: #ebebeb;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 16px;
}

.header-container .left svg {
    font-size: 22px;
    color: black;
}

.header-container .right a img {
    font-size: 22px;
    color: black;
}

.header-container .middle {
    justify-self: center;
    position: relative;
}

.header-container .middle input {
    background-color: white;
    width: 80vw;
    height: 26px;
    padding-left: 8px;
    border-radius: 3px 0px 0px 3px;
    border-left: 1px solid #878787;
    border-top: 1px solid #878787;
    border-bottom: 1px solid #878787;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.header-container .middle button {
    color: #141414;
    margin-top: 2px;
    margin-left: -9vw;
    position: absolute;
    background-color: transparent;
    width: 10vw;
    height: 26px;
    border: 0;
}

.header-container .middle img {
    height: 35px;
}
