.entry-photos-container {
    width: 100vw;
    height: 45px;
    background: #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.entry-photos-container .icon {
    font-size: 28px;
    color: #e82f3e;
    border-bottom: #e82f3e solid 3px;
    padding-bottom: 3px;
}
