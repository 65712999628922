.container-like {
    position: fixed;
    z-index: 50;
    padding: 4px 8px;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    bottom: 135px;
    right: 20px;
    transition: all 2s;
    -webkit-transition: all 2s;
    -moz-transition: all 2s;
    -ms-transition: all 2s;
    -o-transition: all 2s;
}

.container-like svg {
    font-size: 42px;
    margin-left: 0;
}

.container-like span {
    margin-left: 2px;
    margin-right: 2px;
}

.ovl-container-like {
    z-index: 40;
    position: fixed;
    width: 100vw;
    height: 100vh;
}
