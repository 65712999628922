.desktop-loading-ovl {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(
        to right bottom,
        rgba(1, 1, 1, 0.8),
        rgba(1, 1, 1, 0.6)
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.desktop-loading-ovl p {
    padding-top: 50px;
    color: white;
    font-size: 20px;
}
