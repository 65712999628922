.form-newpost-container {
    margin-top: 24px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-newpost-container .group {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-newpost-container .group input,
.form-newpost-container .group textarea,
.form-newpost-container .group .css-2b097c-container {
    width: 100%;
}

.form-newpost-container .group .css-2b097c-container {
    margin-right: 4px;
    margin-left: 0px;
}

.form-newpost-container .group .css-2b097c-container + .css-2b097c-container {
    margin-right: 0px;
    margin-left: 4px;
}

.form-newpost-container .group .inp {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    min-height: 38px;
    outline: 0 !important;
    padding: 6px 8px;
    font-size: 16px;
    margin-top: 16px;
}

.form-newpost-container .group .txt {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    min-height: 114px;
    outline: 0 !important;
    padding: 6px 8px;
    font-size: 16px;
    margin-top: 16px;
}

.form-newpost-container .btn {
    margin-top: 24px;
    width: 90%;
}

.form-newpost-container .btn button {
    width: 100%;
    outline: 0;
    border: 0;
    height: 38px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #e82f3e;
    font-size: 16px;
    font-weight: 600;
    color: white;
}
