.desktop-notify-container {
    background-color: white;
    padding: 16px;
    border-radius: 5px;
    max-height: 400px;
    overflow-y: auto;
}

.desktop-notify-container::-webkit-scrollbar {
    width: 5px;
}

.desktop-notify-container h1 {
    font-size: 10px;
    margin-bottom: 4px;
    font-weight: 300;
}

.desktop-notify-container .not {
    display: flex;
    align-items: center;
}

.desktop-notify-container .not:not(:last-child) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.192);
}

.desktop-notify-container .not img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
}

.desktop-notify-container .not h2 {
    font-size: 16px;
}
