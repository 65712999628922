.lista-comentarios-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.lista-comentarios-container .comm-ind {
    width: 100vw;
    display: flex;
    align-items: flex-start;
}

.lista-comentarios-container .comm-ind + .comm-ind {
    margin-top: 12px;
}

.lista-comentarios-container .comm-ind .left {
    margin-left: 16px;
}

.lista-comentarios-container .comm-ind .left img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
}

.lista-comentarios-container .comm-ind .right {
    margin-left: 12px;
}

.lista-comentarios-container .comm-ind .right h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
}

.lista-comentarios-container .comm-ind .right p {
    margin-top: 4px;
    width: 70vw;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
}

.lista-comentarios-container .comm-ind .delete-comm {
    margin-top: 8px;
}
