.post-list-grid {
    margin-top: 5px;
    display: flex;
    gap: 5px;
}

.post-list-grid_column img {
    width: 100%;
}

.is-loading {
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
}
