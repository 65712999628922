.mobile-listaposts-container {
    background-color: black;
}

.mobile-listaposts-container .menu-sup {
    position: fixed;
    z-index: 60;
    top: 32px;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-listaposts-container .menu-sup a {
    font-size: 16px;
    letter-spacing: 2px;
    color: white;
    text-decoration: none;
}

.mobile-listaposts-container .menu-sup p {
    font-size: 10px;
    color: white;
    margin: 0 8px;
}

.mobile-postonlist-container {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
}

.mobile-postonlist-container .photos {
    width: 100vw;
    height: 100%;
    background-color: black;
    position: relative;
}

.mobile-postonlist-container .photos .photo {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    overflow: hidden;
}

.mobile-postonlist-container .photos .top {
    top: 0;
    position: absolute;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: contain;
    z-index: 50;
}

.mobile-postonlist-container .photos .bot {
    top: 0;
    position: absolute;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
    z-index: 40;
    filter: blur(5px) brightness(50%) contrast(110%);
    -webkit-filter: blur(5px) brightness(50%) contrast(110%);
    transform: scale(1.1);
}

.mobile-postonlist-container .ovl .ovl-top {
    width: 100vw;
    height: 37vh;
    position: absolute;
    top: 0;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 100%
        ),
        transparent;
    background-blend-mode: multiply;
    z-index: 53;
}

.mobile-postonlist-container .ovl .ovl-bot {
    width: 100vw;
    height: 50vh;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.9) 100%
        ),
        transparent;
    background-blend-mode: multiply;
    z-index: 53;
}

.mobile-postonlist-container .resume {
    width: 100%;
    z-index: 55;
    position: absolute;
    bottom: 70px;

    padding: 0 24px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.mobile-postonlist-container .resume .left {
    flex: 1;
    margin-right: 16px;
}

.mobile-postonlist-container .resume .left .first {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-postonlist-container .resume .left .first a {
    color: white;
    text-decoration: none;
    font-size: 14px;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left .first .pontos {
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
}

.mobile-postonlist-container .resume .left .first .pontos svg {
    margin-right: 4px;
}

.mobile-postonlist-container .resume .left h1 {
    margin-top: 2px;

    font-size: 20px;
    font-weight: 500;
    color: white;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left p {
    margin-top: 2px;

    font-size: 13px;
    font-weight: 500;
    color: white;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.mobile-postonlist-container .resume .left p b {
    text-decoration: underline;
}

.mobile-postonlist-container .resume .left p.big {
    font-size: 18px;
}

.mobile-postonlist-container .counter {
    position: absolute;
    z-index: 60;
    top: 32px;
    right: 20px;
    padding: 4px;
    border-radius: 5px;
    background-color: rgba(140, 140, 140, 0.6);
    color: white;
    font-size: 12px;
}

.mobile-postonlist-container .resume .right {
    flex: 0 0 12%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.mobile-postonlist-container .resume .right svg {
    width: 40px;
    height: 40px;

    color: white;

    margin-bottom: 12px;
}

.mobile-postonlist-container .resume .right img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid white;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
}

.mobile-postonlist-container .close {
    position: absolute;
    top: 32px;
    left: 20px;
    color: white;
    z-index: 999;
}

.mobile-postonlist-container .close svg {
    width: 25px;
    height: 25px;
}

.mobile-postonlist-container .apagar {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    z-index: 999;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-postonlist-container .apagar p {
    font-size: 12px;
    margin-bottom: 4px;
}

.mobile-postonlist-container .apagar svg {
    width: 25px;
    height: 25px;
}

.warning-container {
    z-index: 999;
    position: absolute;

    background-color: white;
    width: 80vw;
    padding: 16px;
    border-radius: 5px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.warning-container p {
    text-align: center;
}

.warning-container > div {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}

.warning-container button {
    width: 40%;
    margin: 4px;
    outline: 0;
    border: 0;
    padding: 5px 10px;
    border-radius: 3px;
}

.warning-container button.sim {
    background-color: rgba(165, 0, 0, 0.637);
    font-weight: 700;
    border: 1px solid rgba(75, 0, 0, 0.637);
}

.warning-container button.nao {
    background-color: rgb(219, 219, 219);
    border: 1px solid grey;
}
