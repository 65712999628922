.desktop-new-post-container {
    width: 400px;
    position: absolute;
    background-color: white;
    padding: 24px 0px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.desktop-new-post-container .x {
    position: absolute;
    left: 4px;
    top: 4px;
}

.desktop-new-post-container .x {
    background-color: black;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    height: 24px;
    width: 24px;
}

.desktop-new-post-container .x svg {
    font-size: 24px;
    color: rgb(255, 255, 255);
    z-index: 15;
}
.desktop-new-post-container .slick-slider {
    width: 90% !important;
}
